import { CompanyRoleEnum, SaleChannelTypesEnum } from "./../../company/models/Enums";
import { Component, OnInit } from "@angular/core";
import { NbSidebarService, NbMenuService, NbThemeService, NbMediaBreakpointsService, NbMenuItem } from "@nebular/theme";
import { Subscription, Subject, of } from "rxjs";
import { StateService } from "../../services/state.service";
import { catchError, map, takeUntil } from "rxjs/operators";
import { CustomerDetailService } from "../../services/customer-detail.service";
import { UserDetail } from "../../company/models/UserDetail";
import { environment } from "../../../environments/environment";
import { Params } from "@angular/router";
import DateHelper from "../helpers/date-helper";

@Component({
  selector: "app-shell",
  templateUrl: "./shell.component.html",
  styleUrls: ["./shell.component.scss"],
})
export class ShellComponent implements OnInit {
  sidebar: any = {};
  menu: Array<NbMenuItem> = new Array<NbMenuItem>();

  private _unsubscribeAll: Subject<any>;

  customerDetail: UserDetail;

  protected sidebarState$: Subscription;
  apiUrl: string;
  private wmsRoles = [CompanyRoleEnum.PickerWMS,CompanyRoleEnum.ManagerWMS, CompanyRoleEnum.PackingClerkWMS];
  private adminRole = CompanyRoleEnum.Admin;

  private isWmsUser = false;
  private isAdminUser = false;

  constructor(
    protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
    protected customerDetailService: CustomerDetailService
  ) {
    this.apiUrl = environment.apiUrl;
    this.sidebarState$ = this.stateService.onSidebarState().subscribe((sidebar: string) => {
      this.sidebar = sidebar;
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.customerDetailService.shellMenuObservable
      .pipe(takeUntil(this._unsubscribeAll),
        map((res: UserDetail) => {
          if (res != null) {
            this.customerDetail = res;

            this.isWmsUser = this.customerDetail.companyRoles.every((x) => this.wmsRoles.includes(x.id))
            && this.customerDetail.companyRoles.some((x) => this.wmsRoles.includes(x.id));

            this.isAdminUser = this.customerDetail.companyRoles.some((x) => x.id === this.adminRole);


            this.createNavigationMenu();
          }
        }),
        catchError(err => {
          return of(err);
        }))
      .subscribe();
    }

  private createNavigationMenu() {
    this.menu = new Array<NbMenuItem>();
   if(!this.isWmsUser){

    this.menu.push(<NbMenuItem>{
      data: "home",
      title: "Panou principal",
      icon: "home",
      link: "/home",
      home: true,
    });




    if (this.customerDetail.currentCompany.saleChannelModules.length > 0) {
      // Comenzi
      this.menu.push(<NbMenuItem>{
        data: "orders",
        title: "Comenzi",
        icon: "paper-plane",
        children: [],
      });
      let indexOrders = this.menu.findIndex((x) => x.data == "orders");
      this.customerDetail.currentCompany.saleChannelModules.forEach((saleChannel) => {
        if (saleChannel.includeOrderManagement || saleChannel.type == SaleChannelTypesEnum.Magazin) {
          this.menu[indexOrders].children.push(<NbMenuItem>{
            data: saleChannel.type,
            title: saleChannel.name,
            link: `/base-orders/${saleChannel.name}/${saleChannel.id}`,
          });
        }
      });

      // Retururi
      this.menu.push(<NbMenuItem>{
        data: "returns",
        title: "Retururi",
        icon: "repeat-outline",
        children: [],
      });
      let indexReturns = this.menu.findIndex((x) => x.data == "returns");
      this.customerDetail.currentCompany.saleChannelModules.forEach((saleChannel) => {
        if ((saleChannel.includeOrderManagement || saleChannel.type == SaleChannelTypesEnum.Magazin) && saleChannel.canStorno ) {
          this.menu[indexReturns].children.push(<NbMenuItem>{
            data: saleChannel.type,
            title: saleChannel.name,
            link: `/base-returns/${saleChannel.name}/${saleChannel.id}`,
          });
        }
      });
      // Produse
      this.menu.push(<NbMenuItem>{
        data: "products",
        title: "Produse",
        icon: "list",
        link: "/home",
        children: [],
      });

      if(this.customerDetail.currentCompany.isPartner)
      {
  // Clienti
  this.menu.push(<NbMenuItem>{
    data: "clients",
    title: "Clienti",
    icon: "people-outline",
    link: "ecap-clients",

  });

      }

      let indexProducts = this.menu.findIndex((x) => x.data == "products");
      let mainCompanyId = this.customerDetail.currentCompany.primaryCompanySaleChannelModuleId;
      let mainCompany = this.customerDetail.currentCompany.saleChannelModules.find((x) => x.id == mainCompanyId);

      if (mainCompany != null) {
        this.menu[indexProducts].children.push(<NbMenuItem>{
          data: mainCompany.type,
          title: "Toate produsele",
          link: `/base-products/${mainCompany.name}/${mainCompany.id}`,
          pathMatch: "full",
        });

        this.menu[indexProducts].children.push(<NbMenuItem>{
          data: "prices",
          title: "Lista preturi",
          link: `products-mappings`,
          pathMatch: "full",
        });
      }
    }
    if (this.customerDetail.currentCompany.emagFulfillmentModules.length > 0) {
      this.menu.push(<NbMenuItem>{
        data: "emag-fulfillment",
        title: "eMAG Fulfillment",
        icon: "layers-outline",
        link: "/emag-fulfillment",
      });
    }
    if (this.customerDetail.currentCompany.stockModules.length > 0) {
      this.menu.push(<NbMenuItem>{
        data: "stocks",
        title: "Stocuri",
        icon: "grid",
        link: "/stocks",
      });

      this.menu.push(<NbMenuItem>{
        data: "reports",
        title: "Rapoarte",
        icon: "pie-chart",
        link: "/reports",
        children: [],
      });

      let indexReports = this.menu.findIndex((x) => x.data == "reports");

      this.menu[indexReports].children.push(<NbMenuItem>{
        data: "sales",
        title: "Vanzari",
        link: `/reports/sales`,
        pathMatch: "full",
      });
    }

    if (this.customerDetail.currentCompany.incomeModules.length > 0) {
      this.menu.push(<NbMenuItem>{
        data: "incomes",
        title: "Incasari",
        icon: "bar-chart",
        link: "/incomes",
      });
    }

    if (this.customerDetail.currentCompany.importInvoiceModules.length > 0) {
      this.menu.push(<NbMenuItem>{
        data: "import-invoice",
        title: "Facturi",
        icon: "file-text-outline",
        link: "/import-invoices",
      });
    }

    if (this.customerDetail.currentCompany.feedInModules.length > 0) {
      const feedInModules = this.customerDetail.currentCompany.feedInModules;
      // Comenzi
      this.menu.push(<NbMenuItem>{
        data: "feedIn",
        title: "Feed In",
        icon: "upload",
        link: `/feed-in/${feedInModules[0].name}/${feedInModules[0].id}`,
      });
      //let indexOrders = this.menu.findIndex(x => x.data == "feedIn");
      //this.customerDetail.currentCompany.feedInModules.forEach(feedIn => {

      //    this.menu[indexOrders].children.push(<NbMenuItem>({
      //      data: feedIn.name,
      //      title: feedIn.name,
      //      link: `/feed-in/${feedIn.name}/${feedIn.id}`
      //    }));

      //});
    }

    if (this.customerDetail.currentCompany.feedOutModules.length > 0) {

      const feedOutModules = this.customerDetail.currentCompany.feedOutModules;

      this.menu.push(<NbMenuItem>({
        data: 'feedOut',
        title: 'Feed Out',
        icon: 'download',
        link: `/feed-out/${feedOutModules[0].name}/${feedOutModules[0].id}`
      }));
    }

    if (this.customerDetail.currentCompany.priceDetectorModules.length > 0) {
      const priceDetectorModules = this.customerDetail.currentCompany.priceDetectorModules;
      // Comenzi
      this.menu.push(<NbMenuItem>{
        data: "priceDetector",
        title: "Price Detector",
        icon: "upload",
        link: `/price-detector/${priceDetectorModules[0].name}/${priceDetectorModules[0].id}`,
      });
    }

    this.menu.push(<NbMenuItem>{
      data: "log-dashboard",
      title: "Logs",
      icon: "code",
      link: "/log-summary",
      home: true,
    });

    this.menu.push(<NbMenuItem>{
      data: "log-error",
      title: "Erori",
      icon: "activity-outline",
      link: "/log-error",
      home: true,
    });

    this.menu.push(<NbMenuItem>{
      title: "Tichete",
      icon: "alert-triangle-outline",
      link: "/tichete",
      home: true,
    });

    if(this.customerDetail.currentCompany.id == 329){//caz bujor
      let currentDate = new Date()
      let toDeliveryDate = new Date()
      let fromDeliveryDate = new Date()

      toDeliveryDate.setDate(currentDate.getDate() + 30);
      fromDeliveryDate.setDate(currentDate.getDate());

      const params: Params = {
        deliveryTo: DateHelper.getFilterDateFormatted(toDeliveryDate),
        deliveryFrom: DateHelper.getFilterDateFormatted(fromDeliveryDate)
      }

      this.menu.push(<NbMenuItem>{
        data: "orders",
        title: "Toate comenzile",
        icon: "paper-plane",
        link:  "/ecap-orders",
        queryParams: params
      });
    }
    else{
      this.menu.push(<NbMenuItem>{
        data: "orders",
        title: "Toate comenzile",
        icon: "paper-plane",
        link:  "/ecap-orders"
      });
    }
  }

    if(this.customerDetail.currentCompany.wmsModules.length > 0 && (this.isAdminUser || this.isWmsUser)){
      this.menu.push(<NbMenuItem>{
        title: "WMS",
        icon: "archive",
        link: "/warehouse-orders",
        home: true
      });
    }

    // this.menu.push(<NbMenuItem>{
    //   data: "returns",
    //   title: "Toate retururile",
    //   icon: "repeat-outline",
    //   link: "/ecap-returns",
    // });
  }

  ngOnDestroy() {
    //this.sidebarState$.unsubscribe();
  }
}
